import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ErrorComponent } from '../components/Error'
import Seo from '../components/seo'
import Navigation from '../components/NavBar'
import { ContentfulRichText } from '../components/ContentfulRichText'
import { BlogSnippet } from '../components/BlogSnippet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { SocialIcon } from '../components/SocialIcon'
import { HomePageQueryQuery } from '../generated/graphql'
import { Portfolio } from '../components/Portfolio'
import { library, IconProp } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { HomePageContainer } from '../containers'

library.add(fas, fab)

const HomePage = ({
  data,
  location,
}: PageProps<HomePageQueryQuery, { id: string }>) => {
  if (!data.content) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return <HomePageContainer data={data} location={location} locale="zh" />
}
// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query HomePageQueryZh {
    content: contentfulHomePage(
      contentfulid: { eq: "homepage" }
      node_locale: { eq: "zh" }
    ) {
      headerTextBigScreen {
        file {
          url
        }
      }
      headerTextSmallScreen {
        file {
          url
        }
      }
      mainImage {
        file {
          url
        }
      }
      socialLink {
        icon
        link
      }
      subscribeText {
        raw
      }
      tagline
      subscribeTitle
      portfolioTitle
      writingTitle
      headerTextBigOffset
      email
      contactText {
        raw
      }

      portfolioItems {
        contentful_id
        portfolioCategories {
          categoryName
        }
        id
        link
        icon {
          file {
            url
          }
        }
        description
        name {
          name
        }
      }

      portfolioCategories {
        contentful_id
        categoryName
      }
    }
    blogs: allContentfulBlogPost(
      filter: { node_locale: { eq: "zh" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        body {
          childMarkdownRemark {
            timeToRead
            excerpt
          }
        }
      }
    }
  }
`
export default HomePage
